
import '@fullcalendar/core/vdom';
import Vue from 'vue';
import FullCalendar, {
  CalendarData,
  CalendarOptions,
  EventInput,
} from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/es';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import {
  EnumConfigSchedulerStatus,
  SchedulesStatusLogs,
  TaskScheduler,
} from '@/types';
import parser from 'cron-parser';
import { mapMutations } from 'vuex';

type FullCalendarRef = InstanceType<typeof FullCalendar>;

export default Vue.extend({
  name: 'TaskManagerCalendar',
  components: {
    FullCalendar,
  },
  props: {
    tasksScheduler: {
      type: Array as () => TaskScheduler[],
      required: true,
    },
    schedulesStatusLogs: {
      type: Array as () => SchedulesStatusLogs[],
      required: true,
    },
  },
  data() {
    return {
      calendarOptions: {
        timeZone: 'local',
        plugins: [dayGridPlugin, interactionPlugin],
        defaultTimedEventDuration: 0,
        showNonCurrentDates: false,
        fixedWeekCount: false,
        dayMaxEvents: true,
        events: [],
        headerToolbar: {
          start: 'prev next today',
          center: 'title',
          end: 'dayGridMonth,dayGridWeek,dayGridDay',
        },
        eventTimeFormat: {
          hour: '2-digit',
          minute: '2-digit',
          meridiem: false,
        },
        locale: this.$store.state.auth?.acceptLanguage.toLowerCase(),
        locales: [esLocale, ptBrLocale],
      } as CalendarOptions,
      monthCompensationFactor: 1,
      lastMonthViewed: new Date().getMonth(),
      fullCalendarApi: {} as CalendarData,
    };
  },
  watch: {
    tasksScheduler() {
      this.buildNextEvents();
    },
    schedulesStatusLogs() {
      this.buildPrevEvents();
    },
  },
  mounted() {
    this.handleLoading(true);
    this.buildPrevEvents();
    this.buildNextEvents();
    this.setFullCalendarApi();
    this.addEventsListenerInFullCalendarButtons();
    this.handleLoading(false);
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    buildNextEvents(currentDate?: Date) {
      const nextSchedules = this.tasksScheduler.map((scheduler) => {
        const schedules = [];
        if (scheduler.status === EnumConfigSchedulerStatus.Enabled) {
          let index = 0;

          const dates = this.getNextRunsOfMonth(
            scheduler.config.cron,
            currentDate,
          );

          while (index < dates.length) {
            schedules.push({
              title: this.$t(`taskManager.table.services.${scheduler.service}`) as string,
              message: '',
              date: dates[index],
              className: 'next-event',
            });
            index += 1;
          }
        }
        return schedules;
      });
      const events = [];

      for (
        let scheduler = 0;
        scheduler < nextSchedules.length;
        scheduler += 1
      ) {
        events.push(...nextSchedules[scheduler]);
      }

      (this.calendarOptions.events as EventInput[]).push(...events);
    },
    buildPrevEvents() {
      const logSchedules = this.schedulesStatusLogs.map((log) => ({
        title: this.$t(`taskManager.table.services.${log.service}`) as string,
        message: log.message,
        date: this.$moment(log.executedAt).parseZone().toISOString(),
        className: `log-event ${
          log.statusCode === '200' || log.statusCode === '201'
            ? 'success-event'
            : 'fail-event'
        }`,
      }));

      (this.calendarOptions.events as EventInput[]).push(...logSchedules);
    },
    getNextRunsOfMonth(cron: string, currentDate = new Date()) {
      const dates = [];

      const interval = parser.parseExpression(cron, {
        currentDate: this.$moment(currentDate).subtract(1, 'seconds').toDate(),
        endDate: this.getLastMomentOfMonth(currentDate),
        iterator: true,
      });

      // eslint-disable-next-line no-constant-condition
      while (true) {
        try {
          dates.push(interval.next().value.toISOString());
        } catch {
          break;
        }
      }

      return dates;
    },
    getPrevRun(task: TaskScheduler): string {
      const executedAt = this.schedulesStatusLogs.find(
        (log) => log.schedulerId === task.id,
      )?.executedAt;

      if (executedAt) {
        return this.$moment(executedAt).format('lll');
      }
      return this.$t('taskManager.table.status.notRun') as string;
    },
    getLastMomentOfMonth(date: Date): Date {
      const currentYear = date.getFullYear();
      const currentDay = date.getDate();
      const currentMonth = date.getMonth() + 1;
      const daysInCurrentMonth = new Date(
        currentYear,
        currentMonth,
        0,
      ).getDate();
      const daysToEndMonth = daysInCurrentMonth - currentDay;
      const hoursToEndDay = 24 - date.getHours();
      const minutes = date.getMinutes() + 1;

      return this.$moment(date)
        .add(daysToEndMonth, 'days')
        .add(hoursToEndDay, 'hours')
        .subtract(minutes, 'minutes')
        .toDate();
    },
    getFisrtMomentOfNextMonth(): Date {
      const date = this.$moment()
        .add(this.monthCompensationFactor, 'months')
        .set('date', 1)
        .set('hours', 0)
        .set('minutes', 0)
        .set('seconds', 0)
        .toDate();

      return date;
    },
    setFullCalendarApi(): void {
      this.fullCalendarApi = (this.$refs.fullCalendar as FullCalendarRef)
        .getApi()
        .getCurrentData();
    },
    addEventsListenerInFullCalendarButtons() {
      this.addEventListernerInFullCalendar('fc-today-button ', () => {
        this.setFullCalendarApi();
        this.monthCompensationFactor = 1;
      });

      this.addEventListernerInFullCalendar('fc-dayGridMonth-button', this.setFullCalendarApi);

      this.addEventListernerInFullCalendar('fc-dayGridDay-button', this.setFullCalendarApi);

      this.addEventListernerInFullCalendar('fc-prev-button', this.handlePrevButton);

      this.addEventListernerInFullCalendar('fc-next-button', this.handleNextButton);

      this.addEventListernerInFullCalendar('fc-dayGridWeek-button', this.handleNextButton);
    },
    handleNextButton(): void {
      this.setFullCalendarApi();
      const { start, end } = this.fullCalendarApi.dateProfile.currentRange;
      const lastMonthWithEvents = this.$moment().add(
        this.monthCompensationFactor - 1,
        'months',
      );

      if (
        lastMonthWithEvents.isBefore(start, 'month')
            || lastMonthWithEvents.isBefore(end, 'month')
      ) {
        const fisrtMomentOfNextMonth = this.getFisrtMomentOfNextMonth();

        if (fisrtMomentOfNextMonth.getMonth() > this.lastMonthViewed) {
          this.buildNextEvents(fisrtMomentOfNextMonth);
          this.lastMonthViewed = fisrtMomentOfNextMonth.getMonth();
        }
        this.monthCompensationFactor += 1;
      }
    },
    handlePrevButton(): void {
      const { start: oldStart } = this.fullCalendarApi.dateProfile.currentRange;
      this.setFullCalendarApi();
      const { start } = this.fullCalendarApi.dateProfile.currentRange;

      if (this.$moment(start).isBefore(oldStart)) {
        this.monthCompensationFactor -= 1;
      }
    },
    addEventListernerInFullCalendar(className: string, event: () => void, type = 'click') {
      (this.$refs.fullCalendar as Vue).$el.getElementsByClassName(className)[0]
        .addEventListener(type, event);
    },
  },
});
