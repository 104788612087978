var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("FullCalendar", {
    ref: "fullCalendar",
    staticClass: "task-manager-calendar",
    attrs: { options: _vm.calendarOptions },
    scopedSlots: _vm._u([
      {
        key: "eventContent",
        fn: function (arg) {
          return [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.top",
                    value: arg.event.extendedProps.message,
                    expression: "arg.event.extendedProps.message",
                    modifiers: { top: true },
                  },
                ],
                staticClass: "truncate",
              },
              [
                _c("span", { staticClass: "fc-event-time" }, [
                  _vm._v(" " + _vm._s(arg.timeText) + " "),
                ]),
                _c("span", { staticClass: "fc-event-title" }, [
                  _vm._v(" " + _vm._s(arg.event.title) + " "),
                ]),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }